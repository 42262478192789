@import "../../node_modules/bootstrap/scss/bootstrap";
$primary: #2890e8;
$secondary: #e8c21c;
$dark-blue: #1e396a;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

body {
  font-family: "JF Flat";
  padding: 0;
}

.input-container {
  position: relative;
  margin: 20px;
  direction: rtl;
}

.custom-input {
  direction: rtl;
  padding: 10px;
  // padding-right: 20px;
  // padding-left: 20px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: unset;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }
}

.custom-label {
  position: absolute;
  top: 12px;
  right: -35%;
  width: 100%;
  pointer-events: none;
  color: #999;
}

.custom-textarea-label {
  position: absolute;
  top: 12px;
  right: -38%;
  width: 100%;
  pointer-events: none;
  color: #999;
}

@font-face {
  font-family: "JF Flat";
  src: url("../fonts/jf-flat-regular.ttf");
  font-weight: 500;
  font-style: normal;
}

.navbar-link {
  color: #2890e8;
}
.features {
  width: 100%;
  .features-row {
    display: flex;
    justify-content: start;
    align-items: start;

    .feature-frame {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: all 0.3s;
      overflow: hidden;
      width: 20%;
      height: 600px;
      cursor: pointer;

      @media (max-width: $xl) {
        height: 600px;
      }

      @media (max-width: $lg) {
        display: none;
      }

      .description {
        display: none;
      }

      .info {
        text-align: center;

        .text {
          height: 40px;
        }
      }
    }
    .show-details {
      flex-direction: row;
      flex-shrink: 0;
      flex-basis: 50%;

      .description {
        display: block;
      }
      .info {
        text-align: start;
        padding: 2rem;

        .text {
          height: auto;
        }
      }
    }

    .iphone-mockup {
      /* height: 388px; */
      height: 27rem;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .features-slider {
    @media (min-width: $lg) {
      display: none;
    }

    .feature-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 8px;
      padding-bottom: 50px;
      gap: 10px;
      min-height: 670px;

      .iphone-mockup {
        /* height: 388px; */
        height: 27rem;
        max-width: 100%;
        object-fit: contain;
      }

      .info {
        text-align: center;
        max-width: 600px;
      }
    }
  }
}

.bg-pink {
  background: #fee7e6;
}

.bg-yellow {
  background: #fdf1e8;
}

.bg-green {
  background: #f1fde8;
}

.bg-blue {
  background: #eafafd;
}

.bg-purple {
  background: #eaf4fd;
}

.text {
  color: #2890e8;
}

.text-secondary {
  color: #a28814;
}

#rectangle {
  width: 1440px;
  height: 636.5px;
  left: 0px;
  top: 271px;
  opacity: 0.1;
  background: #aed5f6;
}

// #footer-image {
//   width: 650px;
//   margin: auto;
//   /* top:130rem; */
// }

#phone-icon {
  width: 18px;
  height: 18px;
}

#phone-number {
  text-align: right;
  color: #484546;
  font-size: 13.5px;
  font-family: "Neo Sans Arabic", sans-serif;
  font-weight: 500;
}

#mail-icon {
  width: 18px;
  height: 18px;
}

#email {
  text-align: right;
  color: #484546;
  font-size: 13.5px;
  font-family: "JF Flat", sans-serif;
  font-weight: 500;
}

#app-features,
#contact-us-btn {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #0b7cc0;
  font-size: 12px;
  font-family: "Noto Kufi Arabic", sans-serif;
  font-weight: 700;
}

.send-button {
  width: 102.56px;
  height: 41.5px;
  padding-top: 12.75px;
  padding-bottom: 12.75px;
  background: linear-gradient(#0b7cc0, #203363);
  box-shadow: 0px 5.25px 18.75px rgba(255, 91, 74, 0.2);
  border-radius: 22.5px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.75px;
  word-wrap: "break-word";
}

.w-max-100 {
  max-width: 100%;
}

.m-t-n-50 {
  margin-top: -50px;
}
.m-t-n-70 {
  margin-top: -70px;
}
.m-t-n-100 {
  margin-top: -100px;
}

@media (min-width: 768px) {
  .m-t-md-n-50 {
    margin-top: -50px;
  }
  .m-t-md-n-100 {
    margin-top: -100px;
  }
}

@media (min-width: 992px) {
  .m-t-lg-n-100 {
    margin-top: -100px;
  }
}

.custom-form-control {
  border: 0px;
  border-bottom: 1px solid #b2afaf;
  border-radius: 0;

  &:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: unset;
  }
}

.text-blue-dark {
  color: $dark-blue;
}

.footer-container {
  min-height: 900px;
  position: relative;
  overflow: hidden;
  @media (max-width: $lg) {
    min-height: 600px;
  }

  .footer-curve {
    bottom: 0px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    min-height: 550px;

    .footer-image {
      max-width: 500px;
      top: -30%;
      /*rtl:ignore*/
      left: 10%;
      display: none;
      @media (min-width: $lg) {
        display: block;
        position: absolute;
      }
    }
    
    .side-container /*rtl:ignore*/{
      width: 40%;

      @media (max-width: $lg) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .under-footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid;
      @media (max-width: $md) {
        justify-content: center;

        text-align: center;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      background-color: #aed5f630;
      border-radius: 50% 50% 0 0;
      width: 2500px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
}

.contact-card {
  max-width: 700px;

  .form-card-border {
    border: 15px solid rgba(81, 159, 255, 0.07);
    border-radius: 8px;
  }
}

.owl-dots {
  text-align: center;
  margin-top: -40px;
  button.owl-dot {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    height: 5px;
    width: 15px;
    top: 0px;
    left: 0px;
    position: relative;
    margin: 3px;

    &.active {
      background-color: #8e7611;
    }
  }
}
